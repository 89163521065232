import logo from "../../assets/logo-dark.svg";
import redmonklogo from "../../assets/redmonk-dark.svg";
import bannerAnalytics from "../../assets/banner-analytics_min.webp";
import ImportContacts from "../../assets/import-contacts_min.webp";
import ComposeMessage from "../../assets/compose-message_min.webp";
import SendWithEase from "../../assets/send-with-ease_min.webp";
import React from "react";
import {
  ConfigProvider,
  Typography,
  Button,
  Row,
  Col,
  Timeline,
  Space,
  Flex,
  Avatar,
} from "antd";
import {
  UsergroupAddOutlined,
  IdcardOutlined,
  SignatureOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

const { Title, Paragraph } = Typography;

const LandingPage = () => {
  const features = [
    {
      icon: <UsergroupAddOutlined />,
      title: "Contact Management",
      description:
        "Manage your contacts effortlessly. Import, add, or edit your contacts quickly and efficiently.",
    },
    {
      icon: <IdcardOutlined />,
      title: "Contact Lists",
      description:
        "Organize your contacts into lists or groups. Categorize your audience for targeted messaging.",
    },
    {
      icon: <SignatureOutlined />,
      title: "Quick Sending",
      description:
        "Send your emails with minimal steps. Just compose, select your list, and hit send!",
    },
    {
      icon: <SoundOutlined />,
      title: "Bulk Emailing",
      description:
        "Easily send bulk emails to your contacts without the need for templates. Compose your message and reach your audience in no time.",
    },
    {
      icon: <UsergroupAddOutlined />,
      title: "Easy to Use",
      description:
        "Our tool is designed with simplicity in mind. You don't need email templates, tracking, or automation. Just focus on sending your messages.",
    },
  ];
  const howItWorks = [
    {
      avatar: ImportContacts,
      title: "Step 1: Import Contacts",
      description:
        "Easily import your existing contacts or add new ones effortlessly.",
      children: (
        <>
          <Title level={4}>Import Contacts</Title>
          <Paragraph>
            Easily import your existing contacts or add new ones effortlessly.
          </Paragraph>
        </>
      ),
    },
    {
      avatar: ComposeMessage,
      title: "Step 2: Compose Message",
      description: "Craft your email message without the need for templates.",
      children: (
        <>
          <Title level={4}>Compose Message</Title>
          <Paragraph>
            Craft your email message without the need for templates.
          </Paragraph>
        </>
      ),
    },
    {
      avatar: SendWithEase,
      title: "Step 3: Send with Ease",
      description:
        "Select your contact list and hit send to reach your audience instantly.",
      children: (
        <>
          <Title level={4}>Send with Ease</Title>
          <Paragraph>
            Select your contact list and hit send to reach your audience
            instantly.
          </Paragraph>
        </>
      ),
    },
  ];
  const accessToken = localStorage.getItem("accessToken");

  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: "#f759ab", fontFamily: "Rubik" },
        components: {
          Layout: {
            // headerBg: "#ffffff",
          },
        },
      }}
    >
      <header style={{ background: "#fff0f6" }}>
        <Flex
          gap="large"
          align="center"
          justify="space-between"
          style={{ padding: "1rem 2rem" }}
          wrap
        >
          <img src={logo} alt="Mailinflow Logo" height={40} />
          {accessToken ? (
            <Button type="primary" ghost href="/app">
              Go to Dashboard
            </Button>
          ) : (
            <Flex gap="middle" align="center" justify="flex-start">
              <Button type="text" href="/login">
                Login
              </Button>
              <Button type="primary" href="/login">
                Get Started
              </Button>
            </Flex>
          )}
        </Flex>
      </header>

      <main>
        <section
          style={{
            background: "#fff0f6",
            borderRadius: "0 0 200px 0",
            padding: "4rem 0",
          }}
        >
          <div className="container">
            <Row gutter={30} align="middle">
              <Col sm={24} md={12}>
                <Title style={{ fontSize: "48px", lineHeight: "1.5" }}>
                  Unlock the Power of{" "}
                  <span style={{ color: "#f759ab" }}>
                    Effortless Email Marketing
                  </span>
                </Title>

                <Paragraph>
                  Say goodbye to complex email marketing tools. Mailinflow
                  simplifies bulk emailing and contact management so you can
                  focus on engaging your audience.
                </Paragraph>

                <Button type="primary" size="large" href="/login">
                  Get Started
                </Button>
              </Col>
              <Col sm={24} md={12}>
                <img
                  src={bannerAnalytics}
                  alt="Mailinflow Screen"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>
        </section>

        <section
          style={{
            padding: "4rem 0",
          }}
        >
          <div className="container">
            <Title
              level={2}
              style={{ textAlign: "center", marginBottom: "4rem" }}
            >
              5 ways to work smarter, not harder
            </Title>

            <Row gutter={[16, 16]} align="center">
              {features.map((feature) => (
                <Col xs={24} sm={12} lg={8}>
                  <div
                    style={{
                      padding: "20px",
                      textAlign: "center",
                    }}
                  >
                    <Avatar
                      size={64}
                      icon={feature.icon}
                      style={{ backgroundColor: "#fff0f6", color: "#f759ab" }}
                    />
                    <Title level={4}>{feature.title}</Title>
                    <Paragraph>{feature.description}</Paragraph>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </section>

        <section
          style={{
            padding: "4rem 0",
          }}
        >
          <div className="container">
            <Row gutter={[16, 16]} align="center" justify="center">
              <Col xs={24} sm={24} md={12}>
                <img
                  src={SendWithEase}
                  alt="Mailinflow Screen"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Title
                  level={2}
                  style={{ textAlign: "center", marginBottom: "4rem" }}
                >
                  3 quick steps to use our services
                </Title>
                <Timeline items={howItWorks} />
              </Col>
            </Row>
          </div>
        </section>

        <section
          style={{
            padding: "4rem 0",
          }}
        >
          <div className="container">
            <Space
              direction="vertical"
              align="center"
              style={{
                background: "#fff0f6",
                padding: "40px",
                textAlign: "center",
                borderRadius: "10px",
                display: "flex",
              }}
            >
              <Title level={3}>Start Your Email Marketing Journey Today!</Title>
              <Paragraph>
                Sign up now and enjoy a free email sending limit of 100 emails
                per day during our soft launch period.
              </Paragraph>
              <Button type="primary" size="large" href="/login">
                Get Started
              </Button>
            </Space>
          </div>
        </section>
      </main>

      <footer>
        <Flex
          gap="small"
          align="center"
          vertical
          style={{ padding: "4rem 2rem 2rem" }}
        >
          <p style={{ marginBottom: "0.25rem", fontSize: "65%" }}>Powered By</p>
          <img src={redmonklogo} alt="Redmonk Logo" height={40} />
        </Flex>
        <Flex
          gap="large"
          align="center"
          justify="center"
          style={{ padding: "1rem 2rem 0" }}
          wrap
        >
          <Button
            type="link"
            href="https://redmonkstudios.com/about"
            target="_blank"
          >
            Our Studio
          </Button>
          {/* <span style={{ margin: "0 10px" }}>|</span> */}
          <Button type="link" href="https://redmonkstudios.com/contact-us">
            Contact
          </Button>
          {/* <span style={{ margin: "0 10px" }}>|</span> */}
          <Button
            type="link"
            href="https://redmonkstudios.com//privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Button>
          {/* <span style={{ margin: "0 10px" }}>|</span> */}
          <Button
            type="link"
            href="https://redmonkstudios.com//terms-of-service"
            target="_blank"
          >
            Terms of Service
          </Button>
        </Flex>
        <Flex
          gap="large"
          align="center"
          justify="center"
          style={{ padding: "1rem 2rem 0" }}
        >
          <Paragraph>
            &copy; Copyrights {new Date().getFullYear()} Mailinflow. All rights
            reserved.
          </Paragraph>
        </Flex>
      </footer>
      <SpeedInsights />
      <Analytics />
    </ConfigProvider>
  );
};

export default LandingPage;
