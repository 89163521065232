import React from "react";
import { MailOutlined, UserOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Space,
  Row,
  Col,
  Typography,
  Card,
  Statistic,
  Progress,
  Timeline,
  Empty,
} from "antd";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getReports } from "../../../redux/actions/userAction";

const { Title, Text, Paragraph } = Typography;

export default function Home() {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchReport = () => {
    dispatch(getReports())
      .then((response) => {
        setData(response);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <div className="Home">
      <Space
        style={{
          display: "flex",
          margin: "0 0.5rem 0.25rem 0.5rem",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Title level={5} style={{ marginBottom: 0 }}>
            Welcome Back, {user.emailAddress}
          </Title>
          <Text type="secondary">Dashboard Overview</Text>
        </div>
      </Space>
      <Row gutter={[30, 30]}>
        {data && (
          <Col xs={24} md={12}>
            <Card bordered={false}>
              <Statistic
                title="Emails Per Day"
                value={data.totalEmailsToday}
                suffix={
                  <Text>
                    /{" "}
                    {data.limits.emailPerDay === 0
                      ? "Unlimited"
                      : data.limits.emailPerDay}
                  </Text>
                }
                prefix={<MailOutlined />}
              />
              <Progress
                percent={(
                  (data.totalEmailsToday /
                    (data.limits.emailPerDay === 0
                      ? 10000
                      : data.limits.emailPerDay)) *
                  100
                ).toFixed(0)}
              />
            </Card>
          </Col>
        )}
        {data && (
          <Col xs={24} md={6}>
            <Card bordered={false}>
              <Statistic
                title="Contacts"
                value={data.totalContacts}
                prefix={<UserOutlined />}
                suffix={
                  <Text>
                    /{" "}
                    {data.limits.totalContacts === 0
                      ? "Unlimited"
                      : data.limits.totalContacts}
                  </Text>
                }
                style={{ marginBottom: "0.5rem" }}
              />
              <Text type="secondary">Total Contacts Limit</Text>
            </Card>
          </Col>
        )}
        {data && (
          <Col xs={24} md={6}>
            <Card bordered={false}>
              <Statistic
                title="Campaigns"
                value={data.totalCampaigns}
                prefix={<InboxOutlined />}
                suffix={
                  <Text>
                    /{" "}
                    {data.limits.campaignsPerMonth === 0
                      ? "Unlimited"
                      : data.limits.campaignsPerMonth}
                  </Text>
                }
                style={{ marginBottom: "0.5rem" }}
              />
              <Text type="secondary">Total Campaigns Limit</Text>
            </Card>
          </Col>
        )}
        <Col xs={24} md={12}>
          <Card
            bordered={false}
            title="Welcome to our sample application for a pocket-friendly marketing tool!"
          >
            <Text>
              We're excited to have you explore our platform. Before you dive
              in, there are a few key points we'd like to highlight:
            </Text>
            <ul>
              <li>
                <Text>
                  The tool you are currently using is a demonstration of our
                  work-in-progress marketing solution. It's designed to showcase
                  the core features and concept, but it is not the final
                  product.
                </Text>
              </li>
              <li>
                <Text>
                  Our goal is to offer a pocket-friendly marketing tool, and
                  this sample application provides only essential features. It
                  does not represent the full range of capabilities we plan to
                  implement in the final version.
                </Text>
              </li>
              <li>
                <Text>
                  The purpose of this tool is to give you a glimpse of what's to
                  come. We want your feedback to help us shape the final product
                  and ensure it meets your needs and expectations.
                </Text>
              </li>
            </ul>
            <Text>
              We value your input and welcome any feedback you have. Your
              suggestions and comments will be invaluable in refining and
              enhancing this tool as we work toward the complete version.
            </Text>
          </Card>
          <Card
            bordered={false}
            title="Privacy and Data Usage"
            style={{ marginTop: "2rem" }}
          >
            <Text>
              At Redmonk Studios, we take your data privacy and security
              seriously. Your information, including contact details and
              campaign data, is handled with the utmost care and
              confidentiality. We do not share, sell, or disclose your data to
              third parties. Our commitment is to provide you with a secure and
              reliable platform for your marketing needs. For a detailed
              overview of our data handling practices, please review our Privacy
              Policy [
              <a
                href="https://redmonk.in/privacy-policy/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                https://redmonk.in/privacy-policy/
              </a>
              ]. If you have any questions or concerns regarding your data's
              security, please don't hesitate to reach out us. Your trust is
              important to us, and we are dedicated to safeguarding your data at
              every step.
            </Text>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card bordered={false} title="Activities">
            {data && data.activity.length ? (
              <Timeline items={data.activity}></Timeline>
            ) : (
              <Empty
                description={
                  <Paragraph>There is no data to display.</Paragraph>
                }
              />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
