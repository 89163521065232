import logo from "../../assets/logo-dark.svg";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import {
  HomeOutlined,
  MailOutlined,
  MenuOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Typography, ConfigProvider, Button } from "antd";

import { useEffect, useState } from "react";

const { Header, Footer, Content } = Layout;
const { Paragraph } = Typography;

const items = [
  {
    label: "Home",
    key: "",
    icon: <HomeOutlined />,
    disabled: false,
  },
  {
    label: "Campaigns",
    key: "campaigns",
    icon: <MailOutlined />,
    disabled: false,
  },
  {
    label: "Contacts list",
    key: "contacts",
    icon: <UserOutlined />,
    disabled: false,
  },
  // {
  //   label: "Settings",
  //   key: "settings",
  //   icon: <SettingOutlined />,
  //   disabled: false,
  // },
];

export default function App() {
  const [current, setCurrent] = useState("");
  const navigate = useNavigate();

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(e.key);
  };

  useEffect(() => {
    const path = window.location.pathname.slice(1);
    setCurrent(path);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#f759ab",
          fontFamily: "Rubik",
        },
        components: {
          Layout: {
            // bodyBg: "#fff0f6",
            headerBg: "#ffffff",
          },
        },
      }}
    >
      <Layout className="app-layout">
        <Header>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a href="/">
              <img
                src={logo}
                alt="Mailinflow"
                height={40}
                style={{ display: "block" }}
              />
            </a>
            <Menu
              theme="light"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
              overflowedIndicator={<MenuOutlined />}
              style={{ flexGrow: 1, margin: "0 1rem" }}
            />
            <Button
              danger
              type="link"
              icon={<LogoutOutlined />}
              onClick={() => {
                localStorage.clear();
                if (current !== "") {
                  navigate("/");
                }
              }}
            >
              Logout
            </Button>
          </div>
        </Header>
        <Content>
          {/* <Title level={3}>{items.find((e) => e.key === current).label}</Title> */}
          {/* <FormContact />
          <FormCampaign /> */}
          <Outlet />
        </Content>
        <Footer>
          <Paragraph style={{ textAlign: "center", marginBottom: 0 }}>
            Copyrights 2020 - {new Date().getFullYear()} Redmonk Studios (OPC)
            Pvt Ltd. All rights reserved.
          </Paragraph>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
}
