import * as React from "react";
import { createBrowserRouter, redirect } from "react-router-dom";

// import pages
import App from "../pages/App/App";
import Home from "../pages/App/Home/Home";
import Campaigns from "../pages/App/Campaigns/Campaigns";
import Contacts from "../pages/App/Contacts/Contacts";
import Settings from "../pages/App/Settings/Settings";
import NotFound from "../pages/App/NotFound/NotFound";

import Auth from "../pages/Auth/Auth";
import LandingPage from "../pages/Public/LandingPage";
import Unsubscribe from "../pages/Public/Unsubscribe";

const appRoutes = {
  path: "/app",
  element: <App />,
  children: [
    {
      path: "",
      element: <Home />,
    },
    {
      path: "campaigns",
      element: <Campaigns />,
    },
    {
      path: "contacts",
      element: <Contacts />,
    },
    {
      path: "settings",
      element: <Settings />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};

const authRoutes = {
  path: "/",
  children: [
    {
      path: "",
      element: <LandingPage />,
    },
    {
      path: "login",
      element: <Auth />,
    },
    {
      path: "unsubscribe/:emailAddress/:userEmailAddress/:campaignId?",
      element: <Unsubscribe />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};

// create router
const router = createBrowserRouter(
  localStorage.getItem("accessToken") ? [appRoutes, authRoutes] : [authRoutes]
);

export default router;
