import React from "react";
import { Space, Table, Tag, Typography } from "antd";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listContacts } from "../../../redux/actions/contactsAction";

import FormContact from "../../../components/forms/formContact";
import DeleteRecord from "../../../components/modals/deleteRecord";

const { Title, Text } = Typography;

export default function Contacts() {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts.contacts);
  const contactCount = useSelector((state) => state.contacts.contactCount);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_, record) => (
        <>
          {record.firstName} {record.lastName}
        </>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      key: "emailAddress",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <FormContact
            type="edit"
            formData={record}
            fetchRecords={handleTableChange}
          />
          <DeleteRecord
            id={record._id}
            collection="contacts"
            fetchRecords={handleTableChange}
          />
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    if (!pagination) {
      pagination = tableParams.pagination;
    }
    setLoading(true);
    dispatch(
      listContacts({
        pageSize: pagination.pageSize,
        pageNum: pagination.current,
      })
    )
      .then(() => {
        setTableParams({
          pagination: { ...pagination },
        });
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  // const handleOnActive = (id, active) => {
  //   dispatch(updateCreator({ active: !active }, id)).then(() => {
  //     handleTableChange({
  //       pageSize: tableParams.pagination.pageSize,
  //       current: tableParams.pagination.current,
  //     });
  //   });
  // };

  useEffect(() => {
    handleTableChange({ pageSize: 10, current: 1 });
  }, []);

  return (
    <div className="Contacts">
      <Space
        style={{
          display: "flex",
          margin: "0 0.5rem 0.25rem 0.5rem",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Title level={5} style={{ marginBottom: 0 }}>
            Contacts
          </Title>
          <Text type="secondary">Manage your contacts</Text>
        </div>
        <FormContact type="create" fetchRecords={handleTableChange} />
      </Space>
      <Table
        loading={loading}
        dataSource={contacts}
        scroll={{
          x: 1000,
          y: "calc(100vh - 380px)",
        }}
        columns={columns}
        rowKey="_id"
        pagination={{
          ...tableParams.pagination,
          total: contactCount,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (totalCount) => `Total  ${totalCount}  items`,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}
