import React from "react";
import { SendOutlined } from "@ant-design/icons";
import { Button, Modal, Spin } from "antd";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCampaign } from "../../redux/actions/campaignsAction";

export default function SendEmail({ id, subject, fetchRecords }) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setLoading(true);
    dispatch(
      updateCampaign(
        {
          status: {
            type: "processing",
            description: "Pending",
          },
          subject: subject,
        },
        id
      )
    )
      .then(() => {
        fetchRecords();
        setLoading(false);
        setIsModalOpen(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="link" onClick={showModal}>
        <SendOutlined />
      </Button>
      <Spin spinning={loading}>
        <Modal
          title={"Send email"}
          open={isModalOpen}
          okText="Yes"
          okType="danger"
          cancelText="No"
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>You are about to send out an email. Are you sure?</p>
        </Modal>
      </Spin>
    </>
  );
}
