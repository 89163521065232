import logo from "../../assets/logo-dark.svg";
import redmonklogo from "../../assets/redmonk-dark.svg";
import React, { useEffect } from "react";
import { Space, ConfigProvider } from "antd";
import { useNavigate } from "react-router-dom";

import FormLogin from "../../components/forms/formLogin";

export default function Auth() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/app");
    }
  });

  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: "#f759ab", fontFamily: "Rubik" } }}
    >
      <Space
        direction="vertical"
        align="center"
        style={{ display: "flex", height: "96vh", justifyContent: "center" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "end",
            gap: 15,
          }}
        >
          <img src={logo} alt="Mailinflow Logo" height={60} />
        </div>
        <FormLogin />
      </Space>
      <div
        style={{
          textAlign: "right",
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          gap: 5,
          marginRight: "0.5rem",
        }}
      >
        <p style={{ marginBottom: "0.25rem", fontSize: "65%" }}>Powered By</p>
        <img src={redmonklogo} alt="Redmonk Logo" height={20} />
      </div>
    </ConfigProvider>
  );
}
