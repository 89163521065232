import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";

import { useDispatch } from "react-redux";
import { authValidate, authRequest } from "../../redux/actions/authAction";

export default function FormLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [generateOTP, setGenerateOTP] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = (values) => {
    setLoading(true);
    if (generateOTP) {
      dispatch(authValidate(values))
        .then(() => {
          navigate("/app");
          setLoading(false);
          window.location.reload();
        })
        .catch(() => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    } else {
      dispatch(authRequest(values))
        .then(() => {
          setGenerateOTP(true);
          setLoading(false);
        })
        .catch(() => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    }
  };

  return (
    <Form
      form={form}
      initialValues={{}}
      onFinish={onSubmit}
      style={{
        marginTop: "3rem",
        minWidth: 400,
      }}
    >
      <Form.Item
        name="emailAddress"
        rules={[
          {
            required: true,
            message: "Please input your email address!",
          },
        ]}
      >
        <Input
          placeholder="Email Address"
          onChange={() => setGenerateOTP(false)}
        />
      </Form.Item>

      <Form.Item name="oneTimePassword">
        <Input placeholder="One Time Password" disabled={!generateOTP} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          {generateOTP ? "Login" : "Generate OTP"}
        </Button>
      </Form.Item>
    </Form>
  );
}
